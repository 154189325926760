@import '../../asset/css/variables.scss';

.app6ix-layout {
    &-header {
        position: fixed;
        left: 0;
        right: 0;
        background: var(--hms-ui-colors-surface_dim);
        border-bottom: 1px solid var(--hms-ui-colors-surface_bright);
        top: 0;
        z-index: 999;

        &-inner {
            padding: 8px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 67px;

            @media(max-width:767px) {
                padding: 8px 8px;
                font-size: 13px;
            }
        }

        &-top {
            background-color: var(--hms-ui-colors-black);
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 7px 15px;
            font-size: 14px;
            display: flex;

            @media(max-width:767px) {
                padding: 8px 8px;
                font-size: 13px;
            }

            &-text {
                color: var(--hms-ui-colors-lighter);
                font-weight: 700;
            }

            &-link {
                color: var(--hms-ui-colors-yellow);
            }
        }

        &-left {
            gap: 10px;
            display: flex;
            align-items: center;

            @media(max-width:767px) {
                gap: 5px;
            }
        }

        &-menuButton {
            padding: 0;
            cursor: pointer;

            &:hover {
                svg {
                    path {
                        &[stroke] {
                            stroke: var(--hms-ui-colors-white);
                        }

                        &[fill] {
                            fill: var(--hms-ui-colors-white);
                        }

                    }
                }
            }
        }

        &-lineV {
            width: 1px;
            height: 24px;
            background-color: var(--hms-ui-colors-secondary_default);
        }

        &-logo {
            padding: 0px 5px;
            display: flex;

            &-img {
                max-width: 100%;
                height: 40px;
                max-height: 100%;
            }

            &-text {
                font-size: 13px;
                color: var(--hms-ui-colors-yellow);
                margin: auto -5px 0px -15px;
                padding-right: 5px;
            }
        }

        &-right {
            display: flex;
            align-items: center;
            gap: 10px;

            @media(max-width:767px) {
                gap: 5px;
            }

            &-item {
                position: relative;

                &-btn {
                    cursor: pointer;
                    color: var(--hms-ui-colors-light);
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 125%;
                    text-align: center;
                    width: 48px;
                    height: 48px;
                    @include vendor-prefix(border-radius, 8px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid transparent;

                    @media(max-width:767px) {
                        width: 45px;
                        height: 45px;
                    }

                    svg {
                        margin: auto auto 0;
                    }

                    &-text {
                        margin-top: 5px;
                        margin-bottom: auto;
                        text-transform: uppercase;
                        overflow: hidden;
                        width: 100%;
                        white-space: nowrap;
                    }


                    &.active,
                    &:hover {
                        border-color: var(--hms-ui-colors-yellow);
                        background-color: var(--hms-ui-colors-surface_dim);
                        color: var(--hms-ui-colors-lighter);

                        svg path {
                            &[fill] {
                                fill: var(--hms-ui-colors-lighter);
                            }

                            &[stroke] {
                                stroke: var(--hms-ui-colors-lighter);
                            }
                        }
                    }

                    &-user {
                        width: 30px;
                        height: 30px;
                        @include vendor-prefix(border-radius, 50px);
                        overflow: hidden;
                        margin: auto;
                        display: flex;
                        background-color: var(--hms-ui-colors-surface_bright);

                        svg {
                            margin: auto;
                        }

                        img {
                            object-fit: cover;
                        }
                    }
                }

                &-dropdown {
                    .MuiPaper-root {
                        @include vendor-prefix(border-radius, 11px);
                        border: 1px solid var(--hms-ui-colors-surface_bright);
                        background: var(--hms-ui-colors-surface_dim);
                        box-shadow: 0px 4px 50px 0px var(--hms-ui-colors-surfaceLight);
                        top: 77px !important;
                        min-width: 200px;
                        max-width: 90%;
                        max-height: calc(100% - 120px);
                    }

                    &-space {
                        .MuiPaper-root {
                            top: 112px !important;
                        }
                    }

                    &-language {
                        &-header {
                            color: var(--hms-ui-colors-gray);
                            padding: 8px 8px;
                            font-size: 15px;
                        }

                        .MuiPaper-root {
                            width: 500px;
                            padding: 0px 8px;
                        }

                        &-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }



                        &-item {
                            color: var(--hms-ui-colors-light) !important;
                            font-size: 14px !important;
                            width: 33.33%;
                            @include vendor-prefix(border-radius, 8px !important);
                            padding: 4px !important;

                            &-check {
                                width: 15px;

                                svg {
                                    width: 10px;
                                    height: auto;
                                }
                            }

                            &.active {
                                color: var(--hms-ui-colors-yellow) !important;
                            }

                            @media(max-width:767px) {
                                font-size: 13px !important;
                            }
                        }
                    }

                    &-apps {

                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: var(--hms-ui-colors-lighter);
                            border-bottom: 1px solid var(--hms-ui-colors-surface_bright);
                        }

                        &-list {
                            padding-left: 8px !important;
                            padding-right: 8px !important;

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;

                                span {
                                    font-size: 16px;
                                    color: var(--hms-ui-colors-yellow);
                                }

                                p {
                                    font-size: 14px;
                                    color: var(--hms-ui-colors-secondary);
                                }

                                @media(max-width:767px) {
                                    gap: 5px;

                                    span {
                                        font-size: 15px;
                                    }

                                    p {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }

                    &-users {

                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: var(--hms-ui-colors-lighter);
                            border-bottom: 1px solid var(--hms-ui-colors-surface_bright);
                        }

                        &-list {
                            padding: 0px 8px !important;

                            &-divider {
                                height: 1px;
                                background-color: var(--hms-ui-colors-surface_bright);
                                margin: 8px -8px;
                            }

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;
                                font-size: 14px;
                                color: var(--hms-ui-colors-light);

                                span {
                                    font-size: 14px;
                                }

                                &:hover {
                                    color: var(--hms-ui-colors-lighter);
                                }
                            }
                        }
                    }


                }

                &-accounts {
                    padding-bottom: 50px;

                    &-btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        position: relative;
                        padding-right: 10px;
                        max-width: 230px;
                        cursor: pointer;
                        color: var(--hms-ui-colors-light);
                        font-size: 14px;
                        font-weight: 600;
                        border: 1px solid transparent;
                        height: 48px;
                        @include vendor-prefix(border-radius, 8px);
                        padding: 0px 10px;

                        @media(max-width:767px) {
                            padding: 0px 5px;
                            gap: 5px;
                        }

                        &-logo {
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: var(--hms-ui-colors-secondary_default);
                            border: 1px solid #303740;
                            @include vendor-prefix(border-radius, 50%);
                            overflow: hidden;
                            flex: none;

                            img {
                                height: auto;
                                max-width: 100%;
                                object-fit: cover;
                            }
                        }

                        &-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media(max-width:767px) {
                                display: none;
                            }
                        }

                        &.active,
                        &:hover {
                            border-color: var(--hms-ui-colors-yellow);
                            background-color: var(--hms-ui-colors-surface_dim);
                            color: var(--hms-ui-colors-lighter);

                            svg path {
                                &[fill] {
                                    fill: var(--hms-ui-colors-lighter);
                                }

                                &[stroke] {
                                    stroke: var(--hms-ui-colors-lighter);
                                }
                            }
                        }
                    }

                    &-list {
                        padding: 0px 8px !important;
                        margin-bottom: 10px !important;

                        &-loader {
                            padding: 20px;
                            text-align: center;
                        }

                        &-item {
                            @include vendor-prefix(border-radius, 11px !important);
                            gap: 10px !important;
                            font-size: 14px;
                            color: var(--hms-ui-colors-light);
                            padding: 8px 10px !important;

                            &-logo {
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: var(--hms-ui-colors-secondary_default);
                                border: 1px solid var(--hms-ui-colors-gray);
                                @include vendor-prefix(border-radius, 50%);
                                overflow: hidden;
                                flex: none;

                                img {
                                    height: auto;
                                    max-width: 100%;
                                    object-fit: cover;
                                }
                            }

                            &-name {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &-check {
                                margin-left: auto;

                                svg {
                                    width: 14px;
                                    height: auto;
                                }
                            }

                            &:hover {
                                color: var(--hms-ui-colors-lighter);
                            }
                        }
                    }

                    &-footer {
                        padding: 12px 20px;
                        font-size: 16px;
                        font-weight: 700;
                        color: var(--hms-ui-colors-light);
                        border-top: 1px solid var(--hms-ui-colors-surface_bright);
                        position: sticky;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        right: 0;
                        justify-content: center;
                        gap: 5px;
                        background-color: var(--hms-ui-colors-surface_dim);

                        &:hover {
                            color: var(--hms-ui-colors-yellow);

                            svg path {
                                &[fill] {
                                    fill: var(--hms-ui-colors-yellow);
                                }

                                &[stroke] {
                                    stroke: var(--hms-ui-colors-yellow);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-sidebar {
        position: fixed;
        left: -100%;
        transition: 0.4s;
        width: 320px;
        height: calc(100% - 67px);
        background-color: var(--hms-ui-colors-surfaceLight);
        z-index: 9999999;
        top: 67px;
        padding: 10px;

        &-space {
            top: 102px;
        }

        &.active {
            left: 0%;
        }

        &-inner {
            height: 100%;
            @include vendor-prefix(border-radius, 11px);
            border: 1px solid var(--hms-ui-colors-surface_bright);
            background: var(--hms-ui-colors-surface_dim);
            box-shadow: 0px 4px 50px 0px var(--hms-ui-colors-surfaceLight);
        }

        &-header {
            padding: 16px;
            border-bottom: 1px solid #2B2B2B;
        }

        &-body {
            padding: 10px;
        }

        &-link {
            padding: 12px;
            color: var(--hms-ui-colors-light);
            font-size: 15px;
            line-height: 135%;
            display: flex;
            align-items: center;
            @include vendor-prefix(border-radius, 8px);

            &.active {
                background: var(--hms-ui-colors-surface_bright);
                color: var(--hms-ui-colors-white);
            }

            &:not(.active):hover {
                color: var(--hms-ui-colors-yellow);
            }
        }

        &-overlay {
            height: 100vh;
            position: fixed;
            z-index: 999999;
            cursor: pointer;
            width: 100%;
        }

    }


}