@import "./variables.scss";


.app6ix {
    &-layout {
        height: 100vh;
        width: 100%;
        background-color: var(--hms-ui-colors-black);
        box-sizing: border-box;

        &-content {
            width: 100%;
            // min-height: 100vh;
            //overflow-y: auto;
            padding-top: 67px;

            &-space {
                padding-top: 102px;
            }

            &-page {
                width: 100%;
                padding-top: 24px;
                padding-bottom: 24px;

                @media (max-width: 767px) {
                    padding-top: 18px;
                    padding-bottom: 18px;
                }

                &-nospace {
                    padding: 0px;

                    &-top {
                        padding-top: 0px;
                    }

                    &-bottom {
                        padding-bottom: 0px;
                    }
                }
            }

            &-container {
                max-width: 1130px;
                padding-left: 20px;
                padding-right: 20px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &-popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999999;
        background: rgba(19, 19, 19, 0.7);
        backdrop-filter: blur(10px);
        overflow: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;

        &-close {
            position: absolute;
            cursor: pointer;
            right: 0px;
            top: 0;
            padding: 10px 15px;
        }

        &-inner {
            position: relative;
            color: #fff;
            gap: 10px;
            width: 1000px;
            background: #2b2b2b;
            border: 1px solid #454648;
            border-radius: 6px;
            padding: 24px 24px;
            max-width: 95%;
            margin: auto auto;
        }

        &-sm & {
            &-inner {
                width: 500px;
            }
        }

        &-xs & {
            &-inner {
                width: 400px;
            }
        }

        &-md & {
            &-inner {
                width: 700px;
            }
        }

        &-header {
            &-heading {
                color: #e9e9e9;
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                padding-right: 20px;
                line-height: 130%;

                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }

            &-desc {
                color: var(--hms-ui-colors-secondary);
                font-size: 14px;
                margin-top: 10px;
                text-align: center;
            }
        }

        &-content {
            width: 100%;
            padding: 20px 0px;
        }

        &-footer {
            &-btn {
                gap: 16px;

                &.btn-grey {
                    width: 194px;
                    height: 48px;
                    color: #fff;
                    border: 1px solid #454648;
                    border-radius: 6px;

                    &:active {
                        background-color: #373737;
                    }
                }

                &.btn-gold {
                    width: 194px;
                    height: 48px;
                    color: #fff;
                    border: 1px solid #e3c050;
                    border-radius: 6px;
                    background-color: #121212;
                }
            }
        }
    }


    &-loader {
        color: #fff;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;

        &-fixed {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            z-index: 999999999999999;
            background-color: rgb(33 37 41 / 60%);
            color: #fff;
        }

        &-dark {
            background-color: #000;
            backdrop-filter: blur(3px);
        }
    }

    &-dialog {
        .MuiPaper-root {
            background-color: var(--hms-ui-colors-black);
            border: 1px solid var(--hms-ui-colors-surfaceLighter);
            @include vendor-prefix(border-radius, 11px);
            color: var(--hms-ui-colors-white);
            padding: 15px;

            @media (max-width: 767px) {
                margin: 0;
                max-width: calc(100vw - 32px);
            }

            h2 {
                font-weight: 600;
                font-size: 20px;
                word-break: break-word;
            }
        }

        &-icon {
            svg {
                margin: 10px auto 0;
            }
        }

        &-action {
            justify-content: center !important;
            gap: 10px;
        }

        &-btn {
            min-width: 120px !important;
        }

        &-full {
            .MuiPaper-root {
                width: 100%;
            }
        }
    }

    &-btn {
        @include vendor-prefix(border-radius, 8px !important);
        font-weight: 600 !important;
        box-shadow: none !important;
        padding: 8px 20px !important;

        &-primary {
            border-color: var(--hms-ui-colors-yellow) !important;
            color: var(--hms-ui-colors-white) !important;
            background-color: var(--hms-ui-colors-black) !important;

            &:hover {
                color: var(--hms-ui-colors-yellow) !important;

                svg {
                    path {
                        &[stroke] {
                            stroke: var(--hms-ui-colors-yellow);
                        }

                        &[fill] {
                            fill: var(--hms-ui-colors-yellow);
                        }
                    }
                }
            }
        }

        &-secondary {
            @include vendor-prefix(border-radius, 8px !important);
            border-color: var(--hms-ui-colors-secondary_default) !important;
            color: var(--hms-ui-colors-secondary) !important;

            &:hover {
                background-color: var(--hms-ui-colors-surfaceLighter) !important;
                border-color: var(--hms-ui-colors-surfaceLighter) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-dark {
            @include vendor-prefix(border-radius, 50px !important);
            border-color: var(--hms-ui-colors-dark) !important;
            color: var(--hms-ui-colors-light) !important;
            background-color: var(--hms-ui-colors-dark) !important;

            &:hover {
                border-color: var(--hms-ui-colors-dark-light) !important;
                background-color: var(--hms-ui-colors-dark-light) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-md {
            padding: 11px 30px !important;
        }

        &-sm {
            padding: 5px 15px !important;
            text-transform: none !important;
            font-weight: normal !important;
        }
    }
}


.Toastify {
    z-index: 9999999;
    position: relative;

    &__toast {
        background: var(--hms-ui-colors-surface_bright) !important;
        border: 1px solid var(--hms-ui-colors-surfaceLighter);
    }
}

.w {
    &-auto {
        width: auto;
    }

    &-50 {
        width: 50%;
    }

    &-100 {
        width: 100%;
    }
}

.mt {
    &-auto {
        margin-top: auto !important;
    }

    &-0 {
        margin-top: 0px !important;
    }

    &-5 {
        margin-top: 5px !important;
    }

    &-10 {
        margin-top: 10px !important;
    }

    &-15 {
        margin-top: 15px !important;
    }

    &-20 {
        margin-top: 20px !important;
    }
}

.mb {
    &-auto {
        margin-bottom: auto !important;
    }

    &-0 {
        margin-bottom: 0px !important;
    }

    &-5 {
        margin-bottom: 5px !important;
    }

    &-10 {
        margin-bottom: 10px !important;
    }

    &-15 {
        margin-bottom: 15px !important;
    }

    &-20 {
        margin-bottom: 20px !important;
    }
}

.ml {
    &-auto {
        margin-left: auto !important;
    }

    &-0 {
        margin-left: 0px !important;
    }

    &-5 {
        margin-left: 5px !important;
    }

    &-10 {
        margin-left: 10px !important;
    }

    &-15 {
        margin-left: 15px !important;
    }

    &-20 {
        margin-left: 20px !important;
    }
}

.mr {
    &-auto {
        margin-right: auto !important;
    }

    &-0 {
        margin-right: 0px !important;
    }

    &-5 {
        margin-right: 5px !important;
    }

    &-10 {
        margin-right: 10px !important;
    }

    &-15 {
        margin-right: 15px !important;
    }

    &-20 {
        margin-right: 20px !important;
    }
}

.mx {
    &-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.text {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-underline {
        text-decoration: underline;
    }

    &-primary {
        color: var(--hms-ui-colors-yellow);
    }

    &-light {
        color: var(--hms-ui-colors-light);
    }

    &-white {
        color: var(--hms-ui-colors-white);
    }

    &-link {
        &:hover {
            color: var(--hms-ui-colors-yellow);
        }
    }

    &-danger {
        color: var(--hms-ui-colors-danger);
    }

    &-success {
        color: var(--hms-ui-colors-success);
    }
}

.skiptranslate,
.goog-gt-tt {
    display: none !important;
}