@import "../../asset/css/variables.scss";

.app6ix-layout {
    &-footer {
        width: 100%;

        &-top {
            display: flex;
            width: 100%;
            gap: 36px;
            align-items: center;

            @media(max-width:992px) {
                gap: 26px;
            }

            &-logo {
                width: auto;

                img {
                    width: 40px;
                }
            }

            &-links {
                width: auto;
                display: flex;
                gap: 40px;
                flex: 1;
                flex-wrap: wrap;

                @media(max-width:992px) {
                    gap: 26px;
                }

                @media(max-width:767px) {
                    gap: 0;
                }



                a {
                    color: var(--hms-ui-colors-lighter);
                    font-size: 16px;
                    font-weight: 600;

                    &:hover,
                    &:active {
                        color: var(--hms-ui-colors-yellow);
                    }

                    @media(max-width:767px) {
                        min-width: 33.33%;
                        white-space: nowrap;
                        font-size: 14px;
                    }
                }
            }
        }

        &-line {
            margin-top: 24px;
            margin-bottom: 24px;
            height: 1px;
            width: 100%;
            background-color: var(--hms-ui-colors-surface_dim);
        }

        &-cols {
            display: flex;
            gap: 90px;
            margin: 36px 0px;

            @media(max-width:992px) {
                gap: 25px;
                margin: 26px 0px;
            }

            @media(max-width:767px) {
                flex-direction: column;
            }

            &-box {
                &-heading {
                    color: var(--hms-ui-colors-secondary);
                    font-size: 16px;
                    text-transform: uppercase;
                    margin-bottom: 16px;

                    @media(max-width:767px) {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }

                &-content {
                    display: flex;
                    gap: 36px;

                    @media(max-width:992px) {
                        gap: 16px;
                    }

                    &-col {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        p {
                            font-size: 15px;
                            color: var(--hms-ui-colors-lighter);

                            @media(max-width:767px) {
                                font-size: 14px;
                            }
                        }

                        a {
                            font-size: 15px;
                            color: var(--hms-ui-colors-lighter);

                            @media(max-width:767px) {
                                font-size: 14px;
                            }

                            &:hover,
                            &:active {
                                color: var(--hms-ui-colors-yellow);
                            }
                        }
                    }
                }
            }
        }

        &-reffer {
            display: flex;
            margin: 36px 0px;
            justify-content: center;
            gap: 90px;

            @media(max-width:992px) {
                gap: 40px;
            }

            @media(max-width:767px) {
                flex-direction: column;
                gap: 30px;
            }

            &-link {
                color: var(--hms-ui-colors-lighter);
                font-size: 16px;
                display: flex;
                gap: 16px;
                align-items: center;
                cursor: pointer;

                @media(max-width:767px) {
                    font-size: 14px;
                }

                &:hover,
                &:active {
                    color: var(--hms-ui-colors-yellow);
                }

                &-icon {
                    padding: 5px 15px;
                    color: var(--hms-ui-colors-lighter);
                    background-color: #7A6D40;
                    @include vendor-prefix(border-radius, 4px)
                }

            }
        }

        &-disclaimer {
            width: 100%;

            &-heading {
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 14px;
                color: var(--hms-ui-colors-light);
            }

            &-text {
                font-size: 12px;
                color: var(--hms-ui-colors-secondary);
            }
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            padding-bottom: 24px;

            @media(max-width:767px) {
                flex-direction: column;
                gap: 20px;
            }

            &-col {
                display: flex;
                gap: 16px;

                @media(max-width:767px) {
                    justify-content: center;
                }
            }

            &-links {
                p {
                    color: var(--hms-ui-colors-light);
                    font-size: 16px;

                    @media(max-width:767px) {
                        font-size: 14px;
                    }
                }

                a {
                    color: var(--hms-ui-colors-light);
                    font-size: 16px;

                    @media(max-width:767px) {
                        font-size: 14px;
                    }

                    &:hover,
                    &:active {
                        color: var(--hms-ui-colors-yellow);
                    }
                }
            }

            &-social {
                a {
                    border: 1px solid transparent;
                    @include vendor-prefix(border-radius, 4px);
                    overflow: hidden;

                    &:hover {
                        border-color: var(--hms-ui-colors-light);
                    }
                }
            }
        }
    }
}