@import "../../asset/css/variables.scss";

.app6ix-layout-home {
    &-section {
        width: 100%;
        margin-bottom: 36px;

        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            &-heading {
                font-size: 24px;
                color: var(--hms-ui-colors-lighter);
                font-weight: 700;


                @media(max-width:767px) {
                    font-size: 18px;
                }
            }

            &-action {
                margin-left: auto;
                color: var(--hms-ui-colors-secondary);
                display: flex;
                align-items: center;
                gap: 5px;

                @media(max-width:767px) {
                    font-size: 12px;
                }

                &:hover {
                    color: var(--hms-ui-colors-yellow);

                    svg path {
                        stroke: var(--hms-ui-colors-yellow);
                    }
                }
            }
        }
    }

    &-ai {
        &-heading {
            font-size: 18px;
            color: var(--hms-ui-colors-lighter);
            font-weight: 700;
            margin-bottom: 12px;

            @media(max-width:767px) {
                font-size: 14px;
                margin-bottom: 8px;
            }
        }

        &-search {
            position: relative;

            &-input {
                textarea {
                    padding-right: 60px;

                    @include vendor-placeholder {
                        color: var(--hms-ui-colors-gray);
                        opacity: 1;
                    }
                }

                .MuiInputBase-root {
                    background-color: var(--hms-ui-colors-surfaceLight);
                }

                fieldset {
                    @include vendor-prefix(border-radius, 6px);
                    border-color: var(--hms-ui-colors-secondary_default) !important;
                }

                .Mui-focused {
                    fieldset {
                        border-color: var(--hms-ui-colors-yellow) !important;
                    }
                }
            }

            &-btn {
                position: absolute !important;
                right: 1px;
                top: 1px;
                bottom: 1px;
            }
        }
    }

    &-events {
        &-items {
            // display: flex;
            // margin-bottom: 30px;
            // flex-wrap: wrap;
            // margin-left: -15px;
            // margin-right: -15px;
            margin-bottom: 50px;

            &-box {
                padding-left: 10px;
                padding-right: 10px;


                &-inner {
                    color: var(--hms-ui-colors-white);
                    @include vendor-prefix(border-radius, 11px);
                    position: relative;
                    background: var(--hms-ui-colors-surface_dim);
                    width: 100%;
                    border: 1px solid var(--hms-ui-colors-surface_bright);
                    display: block;
                    overflow: hidden;
                }

                &-media {
                    height: 180px;
                    width: 100%;
                    overflow: hidden;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        min-height: 100%;
                        object-fit: cover;
                        @include vendor-prefix(border-radius, 5px 5px 0px 0px);
                    }
                }

                &-content {
                    width: 100%;
                    padding: 20px 15px;
                }

                &-hosted {
                    font-size: 14px;
                    font-weight: 700;
                    color: var(--hms-ui-colors-lighter);
                    width: 100%;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 5px;

                    @media (max-width: 992px) {
                        font-size: 13px;
                    }
                }

                &-heading {
                    font-weight: 700;
                    font-size: 16px;
                    margin-bottom: 15px;
                    max-width: 100%;
                    text-align: left;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    color: var(--hms-ui-colors-lighter);
                    height: 48px;

                    @media (max-width: 992px) {
                        font-size: 14px;
                        height: 42px;
                    }
                }

                &-date {
                    display: flex;

                    &-text {
                        display: flex;
                        align-items: center;
                        color: var(--hms-ui-colors-lighter);
                        font-size: 14px;
                        white-space: nowrap;
                        overflow: hidden;

                        svg {
                            display: inline-block;
                            margin-right: 5px;
                            width: 16px;
                            height: auto;

                            path {
                                &[fill] {
                                    fill: var(--hms-ui-colors-yellow);
                                }

                                &[stroke] {
                                    stroke: var(--hms-ui-colors-yellow);
                                }
                            }
                        }

                        &:last-child {
                            margin-left: auto;
                            margin-right: 0;
                        }

                        @media (max-width: 992px) {
                            font-size: 13px;
                        }
                    }
                }

                &:hover & {
                    &-hosted {
                        color: var(--hms-ui-colors-white);
                    }

                    &-heading {
                        color: var(--hms-ui-colors-yellow);
                    }
                }
            }

            @media (max-width: 767px) {
                justify-content: center;
            }

            &-dots {
                text-align: center;
                margin-top: 20px;
                position: absolute;
                left: 50%;
                @include vendor-prefix(transform, translateX(-50%));

                li {
                    display: inline-block;
                    padding: 4px;

                    button {
                        width: 11px;
                        height: 11px;
                        @include vendor-prefix(border-radius, 99px);
                        overflow: hidden;
                        background-color: var(--hms-ui-colors-surfaceLighter);
                        font-size: 0;

                        &:hover {
                            background-color: var(--hms-ui-colors-light);
                        }
                    }

                    &.slick-active {
                        button {
                            width: 26px;
                            background-color: var(--hms-ui-colors-yellow) !important;
                        }
                    }
                }


            }

            .slick {
                &-arrow {
                    top: 20px;
                    @include vendor-prefix(border-radius, 8px);
                    border: 1.4px solid var(--hms-ui-colors-surface_dim) !important;
                    height: 36px;
                    width: 36px;

                    &-disabled {
                        display: none !important
                    }

                    &::before {
                        color: var(--hms-ui-colors-surfaceLighter);
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 3.5px;
                        content: "";
                        opacity: 1;
                    }

                    &:hover {
                        &::before {
                            color: var(--hms-ui-colors-white);
                        }
                    }
                }

                &-prev {
                    margin-left: -25px;

                    &::before {
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                    }
                }

                &-next {
                    margin-right: -25px;

                    &::before {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    }
                }


            }
        }

        &-empty {
            @include vendor-prefix(border-radius, 11px);
            border: 1px solid rgba(74, 74, 74, 0.5);
            background: rgba(31, 31, 31, 0.4);
            padding: 40px 20px;
            text-align: center;

            &-text {
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }

    &-markets {
        &-list {
            display: flex;
            gap: 8px;
            overflow: auto;
            padding-bottom: 5px;

            &-item {
                padding: 8px 12px;
                @include vendor-prefix(border-radius, 6px);
                background-color: var(--hms-ui-colors-surface_dim);

                &-label {
                    color: var(--hms-ui-colors-secondary);
                    font-size: 12px;
                    margin-bottom: 5px;
                    white-space: nowrap;
                }

                &-value {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;

                    &-current {
                        font-size: 14px;
                        font-weight: 700;
                        color: var(--hms-ui-colors-white);
                    }

                    &-percent {
                        font-size: 12px;
                        display: flex;
                        gap: 3px;
                        align-items: center;
                    }
                }
            }
        }
    }
}