@import "../../asset/css/variables.scss";

.app6ix-layout-pricing {
    &-section {
        width: 100%;

        &-header {
            text-align: center;
            margin-bottom: 30px;

            &-heading {
                font-size: 36px;
                font-family: var(--hms-ui-fonts-heading);
                color: var(--hms-ui-colors-white);
                font-weight: 900;
                margin-bottom: 10px;

                @media(max-width:767px) {
                    font-size: 30px;
                }
            }

            &-text {
                font-size: 18px;
                color: var(--hms-ui-colors-white);
                font-weight: 700;

                @media(max-width:767px) {
                    font-size: 14px;
                }
            }
        }

        &-row {
            display: flex;
            flex-wrap: wrap;
        }

        &-media {
            width: 70%;
            padding-right: 35px;

            @media(max-width:767px) {
                width: 100%;
                padding: 0px;
            }

            iframe {
                width: 100%;
                height: 100%;
                min-height: 230px;
            }
        }

        &-content {
            width: 30%;
            @include vendor-prefix(border-radius, 11px);
            border: 2px solid #45413A;
            background: linear-gradient(180deg, #0C0C0C 19.82%, rgba(0, 0, 0, 0.00) 100%);
            padding: 20px;

            @media(max-width:767px) {
                width: 100%;
                margin-top: 30px;
            }

            &-label {
                @include vendor-prefix(border-radius, 6px);
                background: var(--hms-ui-colors-secondary_default);
                padding: 5px 12px;
                font-size: 14px;
                color: var(--hms-ui-colors-yellow);
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: 600;
                display: inline-block;
            }

            &-price {
                font-size: 16px;
                color: var(--hms-ui-colors-lighter);
                font-weight: 600;
                margin: 16px 0px 10px;

                @media(max-width:767px) {
                    font-size: 14px;
                }
            }

            &-investor {
                font-size: 16px;
                color: var(--hms-ui-colors-yellow);
                font-weight: 600;

                @media(max-width:767px) {
                    font-size: 14px;
                }
            }

            &-line {
                height: 1px;
                margin: 20px 0px;
                background-color: var(--hms-ui-colors-surface_dim);
            }

            &-popular {
                margin-bottom: 20px;

                &-heading {
                    font-size: 16px;
                    color: var(--hms-ui-colors-lighter);
                    font-weight: 700;
                    margin-bottom: 5px;

                    @media(max-width:767px) {
                        font-size: 14px;
                    }
                }

                &-feature {
                    font-size: 16px;
                    color: var(--hms-ui-colors-secondary);

                    @media(max-width:767px) {
                        font-size: 14px;
                    }
                }
            }

            &-btn {
                width: 100%;

            }
        }

    }
}